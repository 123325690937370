import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaInstagram, FaCalendarAlt, FaInfoCircle } from 'react-icons/fa';
import ReusableModal from '../components/ReusableModal';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import SpinnerOverlay from '../components/SpinnerOverlay'; 
import styles from '../css/Home.module.css'; 

function Home() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [services, setServices] = useState([]);
  const [offeredServices, setOfferedServices] = useState([]);
  const [expandedService, setExpandedService] = useState(null); // Para controlar el acordeón
  const [isLoading, setIsLoading] = useState(false);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(price);
  };

  const formatDuration = (duration) => {
    const [hours, minutes] = duration.split(':').map(Number);
    let formattedDuration = '';
  
    if (hours > 0) {
      formattedDuration += `${hours} hrs `;
    }
    if (minutes > 0) {
      formattedDuration += `${minutes} min`;
    }
  
    return formattedDuration.trim();
  };

  useEffect(() => {
    const fetchServices = async () => {
      setIsLoading(true);
      try {
        const servicesSnapshot = await getDocs(collection(db, 'services'));
        let servicesData = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Filtrar solo los servicios activos y ordenarlos por 'order'
        servicesData = servicesData
          .filter(service => service.active) // Filtrar servicios activos
          .sort((a, b) => a.order - b.order); // Ordenar por campo 'order'
        
        setServices(servicesData);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchOfferedServices = async () => {
      setIsLoading(true);
      try {
        const offeredServicesSnapshot = await getDocs(collection(db, 'serviciosOfrecidos'));
        const offeredServicesData = offeredServicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        offeredServicesData.sort((a, b) => a.order - b.order);
        setOfferedServices(offeredServicesData);
      } finally {
        setIsLoading(false);
      }
    };

    fetchServices();
    fetchOfferedServices();
  }, []);

  const handleOpenModal = (service) => {
    const filteredOfferedServices = offeredServices.filter(offeredService => offeredService.category === service.id);
    setModalContent(filteredOfferedServices);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalContent(null);
    setExpandedService(null); // Cerrar cualquier acordeón abierto al cerrar el modal
  };

  const toggleAccordion = (serviceId) => {
    setExpandedService(prev => prev === serviceId ? null : serviceId);
  };

  return (
    <div className={styles.homeContainer}>
      <Link to="/reservation" className={styles.reservationButton}>
        ¡Reserva tu hora aquí!
      </Link>
      {isLoading && <SpinnerOverlay />}
      <div className={styles.content}>
        <h2 className={styles.title}>Nuestros Servicios</h2>
        <div className={styles.servicesGrid}>
          {services.map(service => (
            <div className={styles.serviceCard} key={service.id} onClick={() => handleOpenModal(service)}>
              <img src={service.image} alt={service.title} className={styles.serviceImage} />
              <h3 className={styles.serviceTitle}>{service.title}</h3>
              <p className={styles.serviceDescription}>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
      <ReusableModal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        title="Lista de Precios"
        content="Aquí puedes ver los servicios ofrecidos:"
      >
        {modalContent && modalContent.length > 0 ? (
          <table className={styles.priceTable}>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Precio</th>
                <th>Info</th>
              </tr>
            </thead>
            <tbody>
              {modalContent.map(service => (
                <React.Fragment key={service.id}>
                  <tr 
                    className={`${service.mostChosen ? styles.mostChosenService : ''} ${styles.serviceRow}`} 
                    onClick={() => toggleAccordion(service.id)}
                  >
                    <td className={styles.serviceNameCell}>
                      {service.name}
                      {service.mostChosen && (
                        <span className={styles.mostChosenLabel}>Más Elegido</span>
                      )}
                    </td>
                    <td>{formatPrice(service.price)}</td>
                    <td>
                      <FaInfoCircle className={styles.infoIcon} />
                    </td>
                  </tr>
                  {expandedService === service.id && (
                    <tr>
                      <td colSpan="3" className={`${styles.accordionContent} ${expandedService === service.id ? styles.expanded : ''}`}>
                        <div className={styles.accordionDetail}>
                          <label className={styles.detalleServicioOfrecido}>{service.detail}, Duración: {formatDuration(service.duration)}</label> <p></p>
                          <Link to="/reservation" className={styles.reservationButtonInDetail}>
                            ¡Reserva tu hora aquí!
                          </Link>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No hay servicios ofrecidos para esta categoría.</p>
        )}
      </ReusableModal>
    </div>
  );
}

export default Home;
