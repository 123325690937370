import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import styles from '../css/ManageProfessionals.module.css';
import SpinnerOverlay from './SpinnerOverlay';

const ManageProfessionals = () => {
  const [professionals, setProfessionals] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [status, setStatus] = useState('Activo');
  const [color, setColor] = useState('#000000');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); // Estado para gestionar si es administrador
  const [editId, setEditId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfessionals = async () => {
      setLoading(true);
      const professionalsSnapshot = await getDocs(collection(db, 'professionals'));
      const professionalsList = professionalsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProfessionals(professionalsList);
      setLoading(false);
    };
    fetchProfessionals();
  }, []);

  const handleAddProfessional = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      let profilePictureUrl = '';
      if (profilePicture) {
        const storage = getStorage();
        const storageRef = ref(storage, `profilePictures/${profilePicture.name}`);
        await uploadBytes(storageRef, profilePicture);
        profilePictureUrl = await getDownloadURL(storageRef);
      }

      await addDoc(collection(db, 'professionals'), {
        uid: user.uid,
        name,
        email,
        contactNumber,
        profilePictureUrl,
        status,
        color,
        isAdmin // Almacena si el usuario es administrador
      });

      setName('');
      setEmail('');
      setPassword('');
      setContactNumber('');
      setProfilePicture(null);
      setStatus('Activo');
      setColor('#000000');
      setIsAdmin(false); // Reinicia el valor a falso
      refreshProfessionals();
    } catch (error) {
      console.error("Error al registrar profesional: ", error);
      alert("Error al registrar profesional. Por favor, intenta nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditProfessional = async (id) => {
    const professional = professionals.find(prof => prof.id === id);
    setName(professional.name);
    setEmail(professional.email);
    setContactNumber(professional.contactNumber || '');
    setStatus(professional.status);
    setColor(professional.color || '#000000');
    setIsAdmin(professional.isAdmin || false); // Carga si es administrador
    setEditId(id);
    setEditMode(true);
  };

  const handleUpdateProfessional = async (e) => {
    e.preventDefault();
    setLoading(true);
    let profilePictureUrl = '';
    if (profilePicture) {
      const storage = getStorage();
      const storageRef = ref(storage, `profilePictures/${profilePicture.name}`);
      await uploadBytes(storageRef, profilePicture);
      profilePictureUrl = await getDownloadURL(storageRef);
    }
    await updateDoc(doc(db, 'professionals', editId), {
      name,
      email,
      contactNumber,
      profilePictureUrl,
      status,
      color,
      isAdmin // Actualiza si es administrador
    });
    setName('');
    setEmail('');
    setContactNumber('');
    setProfilePicture(null);
    setStatus('Activo');
    setColor('#000000');
    setIsAdmin(false); // Reinicia el valor a falso
    setEditId(null);
    setEditMode(false);
    refreshProfessionals();
    setLoading(false);
  };

  const handleDeleteProfessional = async (id) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este profesional?")) {
      setLoading(true);
      await deleteDoc(doc(db, 'professionals', id));
      setProfessionals(professionals.filter(professional => professional.id !== id));
      setLoading(false);
    }
  };

  const refreshProfessionals = async () => {
    setLoading(true);
    const professionalsSnapshot = await getDocs(collection(db, 'professionals'));
    const professionalsList = professionalsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProfessionals(professionalsList);
    setLoading(false);
  };

  const handleFileChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  return (
    <div className={styles.manageProfessionalsContainer}>
      <h2 className={styles.manageProfessionalsHeader}>Gestionar Profesionales</h2>
      {loading && <SpinnerOverlay />}
      <form onSubmit={editMode ? handleUpdateProfessional : handleAddProfessional} className={styles.manageProfessionalsForm}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nombre"
          required
          className={styles.manageProfessionalsInput}
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo Electrónico"
          required
          className={styles.manageProfessionalsInput}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Contraseña"
          required
          className={styles.manageProfessionalsInput}
        />
        <input
          type="text"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
          placeholder="Número de Contacto"
          required
          className={styles.manageProfessionalsInput}
        />
        <input type="file" onChange={handleFileChange} className={styles.manageProfessionalsFileInput} />

        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          required
          className={styles.manageProfessionalsColorInput}
          title="Seleccione un color"
        />

        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          required
          className={styles.manageProfessionalsSelect}
        >
          <option value="Activo">Activo</option>
          <option value="Inactivo">Inactivo</option>
        </select>

        <label>
          <input
            type="checkbox"
            checked={isAdmin}
            onChange={(e) => setIsAdmin(e.target.checked)}
          />
          Administrador
        </label>

        <button type="submit" className={styles.manageProfessionalsButton}>
          {editMode ? 'Actualizar Profesional' : 'Agregar Profesional'}
        </button>
      </form>
      <ul className={styles.manageProfessionalsList}>
        {professionals.map(professional => (
          <li key={professional.id} className={styles.manageProfessionalsListItem}>
            <img src={professional.profilePictureUrl} alt={professional.name} className={styles.manageProfessionalsListItemImg} />
            <div className={styles.manageProfessionalsListItemNameEmail}>
              {professional.name} - {professional.email} - {professional.contactNumber} ({professional.status}) 
              {professional.isAdmin && <strong> (Admin)</strong>}
              <div style={{ backgroundColor: professional.color, width: '20px', height: '20px', display: 'inline-block', marginLeft: '10px' }}></div>
            </div>
            <button onClick={() => handleEditProfessional(professional.id)} className={styles.manageProfessionalsEditButton}>Editar</button>
            <button onClick={() => handleDeleteProfessional(professional.id)} className={styles.manageProfessionalsDeleteButton}>Eliminar</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageProfessionals;
