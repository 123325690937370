import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { query, where, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { FaTrash } from 'react-icons/fa';
import ProfessionalList from './ProfessionalList';
import '../css/ManageReservations.css';
import { FaCopy } from 'react-icons/fa';
import Calendar from './Calendar';
import SpinnerOverlay from './SpinnerOverlay';

const ManageReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [professionals, setProfessionals] = useState({});
  const [services, setServices] = useState({});
  const [availability, setAvailability] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [viewAll, setViewAll] = useState(true); // "Ver Todos" seleccionado por defecto
  const [filterStatus, setFilterStatus] = useState('Pendiente'); // "Pendiente" seleccionado por defecto
  const [activeButton, setActiveButton] = useState('Pendiente'); // Estado para el botón activo
  const [loading, setLoading] = useState(true); // Estado para el spinner

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Muestra el spinner al inicio de la carga
      try {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - 20); // 20 días atrás
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + 40); // 20 días adelante
  
        const startDateString = startDate.toISOString().split('T')[0];
        const endDateString = endDate.toISOString().split('T')[0];
  
        const reservationsRef = collection(db, 'reservations');
        const q = query(reservationsRef, where('date', '>=', startDateString), where('date', '<=', endDateString));
  
        const reservationsSnapshot = await getDocs(q);
        const reservationsList = reservationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        reservationsList.sort((a, b) => new Date(b.reservationDate) - new Date(a.reservationDate));
  
        const professionalsSnapshot = await getDocs(collection(db, 'professionals'));
        const professionalsList = professionalsSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().name;
          return acc;
        }, {});
  
        const servicesSnapshot = await getDocs(collection(db, 'serviciosOfrecidos'));
        const servicesList = servicesSnapshot.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().name;
          return acc;
        }, {});
  
        const availabilitySnapshot = await getDocs(collection(db, 'availability'));
        const availabilityList = availabilitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        setReservations(reservationsList);
        setProfessionals(professionalsList);
        setServices(servicesList);
        setAvailability(availabilityList);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      } finally {
        setLoading(false); // Oculta el spinner al finalizar la carga
      }
    };
  
    fetchData();
  }, []);

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error('Error al copiar:', err));
  };

  const handleApprove = (reservation) => {
    setSelectedReservation(reservation);
    setIsApproveModalOpen(true);
  };

  const handleReject = (reservation) => {
    setSelectedReservation(reservation);
    setIsRejectModalOpen(true);
  };

  const confirmApprove = async () => {
    if (selectedReservation) {
      setIsConfirming(true);
      const reservationRef = doc(db, 'reservations', selectedReservation.id);
      await updateDoc(reservationRef, { status: 'Aprobada' });
      setReservations(reservations.map(res =>
        res.id === selectedReservation.id ? { ...res, status: 'Aprobada' } : res
      ));
      setIsApproveModalOpen(false);
      setSelectedReservation(null);
      setIsConfirming(false);
    }
  };

  const confirmReject = async () => {
    if (selectedReservation) {
      setIsConfirming(true);
      const reservationRef = doc(db, 'reservations', selectedReservation.id);
      await updateDoc(reservationRef, { status: 'Rechazada', comment });
      setReservations(reservations.map(res =>
        res.id === selectedReservation.id ? { ...res, status: 'Rechazada', comment } : res
      ));
      setIsRejectModalOpen(false);
      setSelectedReservation(null);
      setComment('');
      setIsConfirming(false);
    }
  };

  const closeModal = () => {
    setIsApproveModalOpen(false);
    setIsRejectModalOpen(false);
    setIsDetailsModalOpen(false);
    setSelectedReservation(null);
    setComment('');
  };

  const handleDelete = async (reservationId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar esta reserva?")) {
      await deleteDoc(doc(db, 'reservations', reservationId));
      setReservations(reservations.filter(reservation => reservation.id !== reservationId));
    }
  };

  const handleSelectProfessional = (professionalId) => {
    setSelectedProfessional(professionalId);
    setViewAll(false);
  };

  const handleViewAll = () => {
    setSelectedProfessional(null);
    setViewAll(true);
  };

  const handleFilterChange = (status) => {
    setFilterStatus(status);
    setActiveButton(status); // Establece el botón activo
  };

  const filteredReservations = reservations.filter(reservation => {
    if (filterStatus) {
      return reservation.status === filterStatus;
    }
    return true;
  });

  const renderReservations = () => {
    return filteredReservations.map((reservation) => (
      <div key={reservation.id} className="reservation-cardporServicio">
        <div className="reservation-cardporServicio-header">
          <label className='lblNombreServ'>Clienta/e: {reservation.name}</label>
          <div className="reservation-cardporServicio-actions">
            {reservation.status === 'Pendiente' && (
              <>
                <button onClick={() => handleApprove(reservation)}>Aprobar</button>
                <button onClick={() => handleReject(reservation)}>Rechazar</button>
              </>
            )}
            <button onClick={() => handleDelete(reservation.id)}>
              <FaTrash />
            </button>
          </div>
        </div>
        <div className="reservation-cardporServicio-body">
          <label className='masInfo' >Fecha reserva: {reservation.date} hora: {reservation.time}</label>
          <label className='masInfo' >Profesional: {professionals[reservation.professional] || 'N/A'}</label>
          <label className='masInfo'>
            Teléfono clienta/e: {reservation.phone}
            <FaCopy
              onClick={() => copyToClipboard(reservation.phone)}
              style={{ cursor: 'pointer', marginLeft: '8px' }}
              title={copied ? "Copiado!" : "Copiar"}
            />
            {copied && <span style={{ marginLeft: '5px', color: 'green' }}>Copiado!</span>}
          </label>
          <label className='masInfo'>
            Correo clienta/e: {reservation.email}
            <FaCopy
              onClick={() => copyToClipboard(reservation.email)}
              style={{ cursor: 'pointer', marginLeft: '8px' }}
              title={copied ? "Copiado!" : "Copiar"}
            />
            {copied && <span style={{ marginLeft: '5px', color: 'green' }}>Copiado!</span>}
          </label>
          <label className='lblDuracionServ'>Estado: {reservation.status}</label>
          <label className='lblDuracionServ'>Fecha de solicitud: {reservation.reservationDate}</label>
          <label className='masInfo' >Servicios: </label>
          {reservation.services.map((service, index) => (
            <label className='lblDuracionServ' key={index}>
              {services[service.id] || service.name} - {service.duration} minutos
            </label>
          ))}
          {reservation.comment && <label className='lblDuracionServ'>Comentario: {reservation.comment}</label>}
        </div>
      </div>
    ));
  };

  return (
    <div className="manage-reservations-content">
      {loading && <SpinnerOverlay />} {/* Muestra el spinner mientras carga */}
      <div className="divAlignCenter">
        <label className="lblTitulo">Listado de reservas</label>
      </div>
      {/* Filtros de estado */}
      <div className="filter-group">
        <button
          className={`botonesRes ${activeButton === '' ? 'active' : ''}`}
          onClick={() => handleFilterChange('')}
        >
          Todos
        </button>
        <button
          className={`botonesRes ${activeButton === 'Pendiente' ? 'active' : ''}`}
          onClick={() => handleFilterChange('Pendiente')}
        >
          Pendientes
        </button>
        <button
          className={`botonesRes ${activeButton === 'Aprobada' ? 'active' : ''}`}
          onClick={() => handleFilterChange('Aprobada')}
        >
          Aprobadas
        </button>
        <button
          className={`botonesRes ${activeButton === 'Rechazada' ? 'active' : ''}`}
          onClick={() => handleFilterChange('Rechazada')}
        >
          Rechazadas
        </button>
      </div>

      <div className="reservation-list">
        {renderReservations()}
      </div>

      {isApproveModalOpen && (
        <div className="manage-reservations-modal" id="reservation-modal">
          <div className="modal-content">
            <h3>Confirmar Aprobación</h3>
            <p>
              ¿Está seguro de aprobar la reserva al cliente/a: {selectedReservation?.name}?
            </p>
            <button onClick={confirmApprove} disabled={isConfirming}>Aceptar</button>
            <button onClick={closeModal} disabled={isConfirming}>Cancelar</button>
          </div>
        </div>
      )}

      {isRejectModalOpen && (
        <div className="manage-reservations-modal" id="reservation-modal">
          <div className="modal-content">
            <h3>Confirmar Rechazo</h3>
            <p>
              ¿Está seguro de rechazar la reserva al cliente/a: {selectedReservation?.name}?
            </p>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Ingrese un comentario"
              required
            />
            <button onClick={confirmReject} disabled={isConfirming}>Aceptar</button>
            <button onClick={closeModal} disabled={isConfirming}>Cancelar</button>
          </div>
        </div>
      )}
      <div className="divAlignCenter">
        <label className="lblTitulo">Agenda</label>
      </div>
      <div className="button-group">
        <ProfessionalList
          professionals={professionals}
          onSelectProfessional={handleSelectProfessional}
          handleViewAll={handleViewAll}
        />
      </div>
      <div className="manage-reservations-content">
        <Calendar 
          selectedProfessional={selectedProfessional} 
          viewAll={viewAll} 
          loading={loading} // Pasamos el estado de carga al componente Calendar
        />
      </div>
    </div>
  );
};

export default ManageReservations;
