import React, { useEffect } from 'react';
import Modal from 'react-modal';
import styles from '../css/ReusableModal.module.css';

Modal.setAppElement('#root');

const ReusableModal = ({ isOpen, onRequestClose, title, content, children }) => {

  useEffect(() => {
    // Bloquear el scroll cuando el modal está abierto
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      // Restaurar el scroll cuando el modal se cierra
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.reservationModal}
      overlayClassName={styles.reservationOverlay}
      shouldCloseOnOverlayClick={true}
    >
      <div className={styles.contenidoModalTitulo}>
        <label className={styles.lblSubtituloModal}>{title}</label>
        <button onClick={onRequestClose} className={styles.closeButton}>&times;</button>
      </div>
      <label className={styles.lblContenido}>{content}</label>
      {children}
    </Modal>
  );
};

export default ReusableModal;
