import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { db } from '../firebase';
import { query, where, getDocs, collection } from 'firebase/firestore';
import ReusableModal from './ReusableModal';
import { FaCopy } from 'react-icons/fa';
import SpinnerOverlay from './SpinnerOverlay'; // Importa el spinner

const Calendar = ({ selectedProfessional, viewAll, loading }) => {
    const [events, setEvents] = useState([]);
    const [professionals, setProfessionals] = useState({});
    const [selectedEvent, setSelectedEvent] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const today = new Date();
          const startDate = new Date(today);
          startDate.setDate(today.getDate() - 20); // 20 días atrás
          const endDate = new Date(today);
          endDate.setDate(today.getDate() + 20); // 20 días adelante
  
          const startDateString = startDate.toISOString().split('T')[0];
          const endDateString = endDate.toISOString().split('T')[0];
  
          const professionalsSnapshot = await getDocs(collection(db, 'professionals'));
          const professionalsList = professionalsSnapshot.docs.reduce((acc, doc) => {
            acc[doc.id] = { 
              name: doc.data().name, 
              color: doc.data().color || '#000000'
            };
            return acc;
          }, {});
  
          setProfessionals(professionalsList);
  
          const reservationsRef = collection(db, 'reservations');
          const q = query(reservationsRef, where('date', '>=', startDateString), where('date', '<=', endDateString));
          
          const reservationsSnapshot = await getDocs(q);
          const reservationsList = reservationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
          const filteredReservations = reservationsList.filter(reservation => {
            return viewAll || reservation.professional === selectedProfessional;
          });
  
          const eventsData = filteredReservations.map(reservation => {
            const [startHour, startMinute] = reservation.time.split(':').map(Number);
            const [durationHours, durationMinutes] = reservation.totalDuration.split(':').map(Number);
  
            let endHour = startHour + durationHours;
            let endMinute = startMinute + durationMinutes;
  
            if (endMinute >= 60) {
              endHour += Math.floor(endMinute / 60);
              endMinute = endMinute % 60;
            }
  
            const endTime = new Date(`${reservation.date}T${String(endHour).padStart(2, '0')}:${String(endMinute).padStart(2, '0')}:00`);
  
            return {
              id: reservation.id,
              title: `${reservation.name} - ${reservation.services.map(service => service.name).join(', ')}`,
              start: new Date(`${reservation.date}T${reservation.time}:00`),
              end: endTime,
              backgroundColor: professionalsList[reservation.professional]?.color || '#000000',
              extendedProps: {
                phone: reservation.phone,
                email: reservation.email,
                status: reservation.status,
                services: reservation.services,
                comment: reservation.comment,
                professionalName: professionalsList[reservation.professional]?.name || 'Desconocido',
              }
            };
          });
  
          setEvents(eventsData);
        } catch (error) {
          console.error("Error al cargar los datos:", error);
        } 
      };
  
      if (selectedProfessional || viewAll) {
        fetchData();
      }
    }, [selectedProfessional, viewAll]);
  
    const handleEventClick = (info) => {
      setSelectedEvent(info.event);
    };
  
    const closeModal = () => {
      setSelectedEvent(null);
    };
  
    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch(err => console.error('Error al copiar:', err));
    };
  
    const formatDuration = (duration) => {
      const [hours, minutes] = duration.split(':').map(Number);
      let formattedDuration = '';
    
      if (hours > 0) {
        formattedDuration += `${hours} hrs `;
      }
      if (minutes > 0) {
        formattedDuration += `${minutes} min`;
      }
    
      return formattedDuration.trim();
    };
  
    return (
      <>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          events={events}
          locale={esLocale}
          firstDay={1}
          slotMinTime="10:00:00" 
          slotMaxTime="21:00:00"
          height="auto" 
          eventClick={handleEventClick}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
        />
  
        {selectedEvent && (
          <ReusableModal
            isOpen={!!selectedEvent}
            onRequestClose={closeModal}
            title="Detalles de la Reserva"
          >
            <div className="reservation-cardporServicio-body">
              <label className='lblNombreServ'>Clienta/e: {selectedEvent.title}</label>   
              <label className='masInfo'>
                  Teléfono clienta/e: {selectedEvent.extendedProps.phone}
                  <FaCopy 
                    onClick={() => copyToClipboard(selectedEvent.extendedProps.phone)} 
                    style={{ cursor: 'pointer', marginLeft: '8px' }} 
                    title={copied ? "Copiado!" : "Copiar"} 
                  />
                  {copied && <span style={{ marginLeft: '5px', color: 'green' }}>Copiado!</span>}
              </label>
  
              <label className='masInfo'>
                  Correo clienta/e: {selectedEvent.extendedProps.email}
                  <FaCopy 
                  onClick={() => copyToClipboard(selectedEvent.extendedProps.email)} 
                  style={{ cursor: 'pointer', marginLeft: '8px' }} 
                  title={copied ? "Copiado!" : "Copiar"} 
                  />
                  {copied && <span style={{ marginLeft: '5px', color: 'green' }}>Copiado!</span>}
              </label>
  
              <label className='lblDuracionServ'>Estado:  {selectedEvent.extendedProps.status}</label>
              <label className='masInfo'>Profesional: {selectedEvent.extendedProps.professionalName}</label>
  
              <label className='masInfo'>Servicios: </label>
              
              {selectedEvent.extendedProps.services.map((service, index) => (
              <label className='lblDuracionServ' key={index}>
                  {service.name} - {formatDuration(service.duration)}  
              </label>
              ))}
        
              {selectedEvent.extendedProps.comment && <label className='lblDuracionServ'>Comentario: {selectedEvent.extendedProps.comment}</label>}
            </div>
          </ReusableModal>
        )}
      </>
    );
  };
  
  export default Calendar;