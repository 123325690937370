import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import { FaWhatsapp, FaInstagram, FaCalendarAlt, FaMapMarkerAlt, FaConciergeBell, FaUserCog, FaCalendarCheck, FaUserAlt, FaList, FaPlusCircle, FaSignInAlt, FaUserPlus, FaHome, FaTools } from 'react-icons/fa'; 
import Home from './view/Home';
import Experiences from './view/Experiences';
import Schedule from './view/Schedule';
import Reservation from './view/Reservation';
import ManageProfessionals from './components/ManageProfessionals';
import ManageAvailability from './components/ManageAvailability';
import GestionServicios from './components/GestionServicios';
import ManageReservations from './components/ManageReservations';
import Login from './components/Login';
import Signup from './components/Signup';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <div className="navbar-left">
            <Link to="/">
              <img src="./mnnofondo2.png" alt="Logo" className="navbar-logo" />
            </Link>
          </div>
          <div className="navbar-right">
            <ul className="navbar-menu">
              <li className="navbar-item">
                <Link to="/">
                  <FaHome className="icon" />
                  <span>Detalles de nuestros servicios</span>
                </Link>
              </li>
              <li className="navbar-item">
                <Link to="/reservation">
                  <FaCalendarAlt className="icon calendar" />
                  <span>Reservar</span>
                </Link>
              </li>
              <li className="navbar-item">
                <a href="https://wa.me/+56941322787" target="_blank" rel="noopener noreferrer">
                  <FaWhatsapp className="icon whatsapp" />
                  <span>Contacto WhatsApp</span>
                </a>
              </li>
              <li className="navbar-item">
                <a href="https://www.instagram.com/mary.nails.cl/?locale=es_ES%2F" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="icon instagram" />
                  <span>Instagram</span>
                </a>
              </li>
             
              {isLoggedIn && (
                <li className="navbar-item">
                  <div className="dropdown">
                  <button className="dropdown-button">
                    <FaTools className="icon" />
                    <span>Administración</span>
                  </button>
                    <div className="dropdown-content">
                      <Link to="/gestion-servicios">
                        <FaConciergeBell className="icon" />
                        <span>Servicios</span>
                      </Link>
                      <Link to="/manage-professionals">
                        <FaUserCog className="icon" />
                        <span>Gestionar Profesionales</span>
                      </Link>
                      <Link to="/manage-availability">
                        <FaCalendarAlt className="icon" />
                        <span>Gestionar Disponibilidad</span>
                      </Link>
                      <Link to="/manage-reservations">
                        <FaCalendarCheck className="icon" />
                        <span>Gestionar Reservas</span>
                      </Link>
                     
                    </div>
                  </div>
                </li>
              )}
              {!isLoggedIn && (
                <li className="navbar-item">
                  <Link to="/login">
                    <FaSignInAlt className="icon" />
                    <span>Login</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </nav>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/experiences" element={<Experiences />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/reservation" element={<Reservation />} />
            {isLoggedIn && (
              <>
                <Route path="/gestion-servicios" element={<GestionServicios />} />
                <Route path="/manage-professionals" element={<ManageProfessionals />} />
                <Route path="/manage-availability" element={<ManageAvailability />} />
                <Route path="/manage-reservations" element={<ManageReservations />} />
              </>
            )}
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/signup" element={<Signup setIsLoggedIn={setIsLoggedIn} />} />
          </Routes>
        </main>
        <footer className="footer">
          <div className="footer-links">
            <a href="https://wa.me/+56941322787" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp className="icon whatsapp" />
              <span>Contacto WhatsApp</span>
            </a>
            <a href="https://www.instagram.com/mary.nails.cl/?locale=es_ES%2F" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="icon instagram" />
              <span>Instagram</span>
            </a>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
