import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, query, where, deleteDoc, doc, updateDoc } from 'firebase/firestore';

const ManageAvailability = () => {
  const [professionals, setProfessionals] = useState([]);
  const [availability, setAvailability] = useState({});
  const [selectedProfessional, setSelectedProfessional] = useState('');
  const [fecInic, setDateIni] = useState('');
  const [fecFin, setDateFin] = useState('');
  const [weeklyAvailability, setWeeklyAvailability] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(0);

  useEffect(() => {
    const fetchProfessionalsAndAvailability = async () => {
      const professionalsSnapshot = await getDocs(collection(db, 'professionals'));
      const professionalsData = professionalsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProfessionals(professionalsData);

      fetchAvailability();
    };
    fetchProfessionalsAndAvailability();
  }, []);

  const fetchAvailability = async () => {
    const availabilitySnapshot = await getDocs(collection(db, 'availability'));
    const availabilityData = availabilitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setWeeklyAvailability(availabilityData);
  };

  const handleChange = (e, day) => {
    const { name, value } = e.target;
    setAvailability(prevState => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [name]: value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'availability'), {
        professionalId: selectedProfessional,
        availability,
        fecInic,
        fecFin
      });
      await updateDoc(doc(db, 'availability', docRef.id), { id: docRef.id }); // Update the document with its own ID
      alert('Disponibilidad guardada con éxito!');
      setAvailability({});
      setSelectedProfessional('');
      setDateIni('');
      setDateFin('');
      fetchAvailability();
    } catch (error) {
      console.error('Error al guardar la disponibilidad: ', error);
    }
  };

  const handleDeleteAvailability = async (availabilityId) => {
    try {
      await deleteDoc(doc(db, 'availability', availabilityId));
      alert('Disponibilidad eliminada con éxito!');
      fetchAvailability();
    } catch (error) {
      console.error('Error al eliminar la disponibilidad: ', error);
    }
  };

  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  const getWeekDates = (weekOffset = 0) => {
    const now = new Date();
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(now);
      date.setDate(now.getDate() - now.getDay() + i + 1 + weekOffset * 7); // Adjust for week starting on Monday
      weekDates.push(date);
    }
    return weekDates;
  };

  const weekDates = getWeekDates(currentWeek);

  const generateTimeSlots = (startTime, endTime, interval) => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const slots = [];
    while (start < end) {
      slots.push(start.toTimeString().substring(0, 5));
      start.setMinutes(start.getMinutes() + interval);
    }
    return slots;
  };

  const timeSlots = generateTimeSlots('10:00', '21:00', 30);

  const isWithinDateRange = (date, startDate, endDate) => {
    const d = new Date(date).setHours(0, 0, 0, 0); // Convert date to start of the day
    const start = new Date(startDate).setHours(0, 0, 0, 0); // Convert startDate to start of the day
    const end = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1)).setHours(0, 0, 0, 0); // Convert endDate to end of the day plus one day
    return d >= start && d <= end;
  };

  return (
    <div className="content">
      <h2>Gestionar disponibilidad</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Profesional</label>
          <select
            value={selectedProfessional}
            onChange={(e) => setSelectedProfessional(e.target.value)}
            required
          >
            <option value="">Seleccione un profesional</option>
            {professionals.map(professional => (
              <option key={professional.id} value={professional.id}>
                {professional.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Fecha de inicio</label>
          <input
            type="date"
            value={fecInic}
            onChange={(e) => setDateIni(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Fecha de término</label>
          <input
            type="date"
            value={fecFin}
            onChange={(e) => setDateFin(e.target.value)}
            required
          />
        </div>
        <h2>Marcar días de trabajo</h2>
        {daysOfWeek.map(day => (
          <div key={day} className="availability-group">
            <label>{day}</label>
            <div>
              <label>Hora de inicio</label>
              <input 
                type="time" 
                name="start" 
                value={availability[day]?.start || ''} 
                onChange={(e) => handleChange(e, day)} 
              />
            </div>
            <div>
              <label>Hora de término</label>
              <input 
                type="time" 
                name="end" 
                value={availability[day]?.end || ''} 
                onChange={(e) => handleChange(e, day)} 
              />
            </div>
          </div>
        ))}
        <button type="submit">Guardar Disponibilidad</button>
      </form>

      <div className="week-buttons">
        <button onClick={() => setCurrentWeek(prev => prev - 1)}>Semana Anterior</button>
        <button onClick={() => setCurrentWeek(0)}>Semana Actual</button>
        <button onClick={() => setCurrentWeek(prev => prev + 1)}>Siguiente Semana</button>
      </div>

      <h2>Disponibilidad semanal</h2>
      <table className="weekly-schedule">
        <thead>
          <tr>
            <th>Profesional</th>
            <th>Horas</th>
            {weekDates.map((date, index) => (
              <th key={index}>
                {daysOfWeek[index]}
                <br />
                {date.toLocaleDateString('es-CL')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {professionals.map(professional => (
            timeSlots.map((timeSlot, slotIndex) => (
              <tr key={`${professional.id}-${timeSlot}-${slotIndex}`}>
                {slotIndex === 0 && (
                  <td rowSpan={timeSlots.length}>
                    {professional.name}
                    {weeklyAvailability
                      .filter(avail => avail.professionalId === professional.id)
                      .map(avail => (
                        <div key={avail.id}>
                          <button onClick={() => handleDeleteAvailability(avail.id)}>Eliminar Disponibilidad</button>
                        </div>
                      ))}
                  </td>
                )}
                <td>{timeSlot}</td>
                {daysOfWeek.map((day, index) => (
                  <td key={`${day}-${timeSlot}-${professional.id}`}>
                    {weeklyAvailability.find(avail => 
                      avail.professionalId === professional.id &&
                      avail.availability[day] &&
                      avail.availability[day].start <= timeSlot &&
                      avail.availability[day].end >= timeSlot &&
                      isWithinDateRange(weekDates[index], avail.fecInic, avail.fecFin)) ? (
                      <div className="hour-block">{timeSlot}</div>
                    ) : ''}
                  </td>
                ))}
              </tr>
            ))
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageAvailability;
