import React from 'react';
import '../css/Experiences.css';

function Experiences() {
  const experiences = [
    { id: 1, image: 'image1.jpg', detail: 'Detalle de la experiencia 1' },
    { id: 2, image: 'image2.jpg', detail: 'Detalle de la experiencia 2' },
    { id: 3, image: 'image3.jpg', detail: 'Detalle de la experiencia 3' },
  ];

  return (
    <div className="experiences">
      <h2>Experiencias</h2>
      <ul>
        {experiences.map(exp => (
          <li key={exp.id}>
            <img src={exp.image} alt={exp.detail} />
            <p>{exp.detail}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Experiences;
