import React from 'react';

const ProfessionalList = ({ professionals, onSelectProfessional, handleViewAll }) => {
  return (
    <div className="professional-list">
      <button className="botonesRes" onClick={handleViewAll}>
        Ver Todos
      </button>
      {Object.entries(professionals).map(([professionalId, professionalName]) => (
        <button className="botonesRes" key={professionalId} onClick={() => onSelectProfessional(professionalId)}>
          {professionalName}
        </button>
      ))}
    </div>
  );
};

export default ProfessionalList;
