import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

const Registro = ({ setIsLoggedIn }) => {
  const [correo, setCorreo] = useState('');
  const [contrasena, setContrasena] = useState('');

  const manejarEnvio = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, correo, contrasena);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("Error al crear el usuario: ", error);
      alert("Error al crear el usuario. Por favor, inténtalo nuevamente.");
    }
  };

  return (
    <div className="content">
      <form onSubmit={manejarEnvio}>
        <h3 className="lblTitulo">Registrarse</h3>
        <div className="form-group">
          <label>Correo Electrónico</label>
          <input 
            type="email" 
            value={correo} 
            onChange={(e) => setCorreo(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label>Contraseña</label>
          <input 
            type="password" 
            value={contrasena} 
            onChange={(e) => setContrasena(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="cta-button">Registrarse</button>
      </form>
    </div>
  );
};

export default Registro;
