import React, { useState, useEffect, useRef } from 'react';
import DayCarousel from '../components/FechaReserva';
import { db } from '../firebase';
import { collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import { format, parseISO, isAfter, isToday } from 'date-fns';
import { es } from 'date-fns/locale';
import { FcInfo } from "react-icons/fc";
import ReusableModal from '../components/ReusableModal';
import SpinnerOverlay from '../components/SpinnerOverlay';
import styles from '../css/Reservation.module.css';
import sendEmailTemplate from '../components/email';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Reservation = () => {
  const navigate = useNavigate();
  const dateRef = useRef(null);
  const horaRef = useRef(null);
  const nombreRef = useRef(null);
  const correoRef = useRef(null);
  const numeroRef = useRef(null);
  const servicioRef = useRef(null);

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [professional, setProfessional] = useState('');
  const [phone, setPhone] = useState('+569');
  const [email, setEmail] = useState('');
  const [services, setServices] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [selectedServiceDetails, setSelectedServiceDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [approvedReservations, setApprovedReservations] = useState([]);
  const [errors, setErrors] = useState({});
  const [servicesOffered, setServicesOffered] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [currentServiceDetail, setCurrentServiceDetail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceStepEnabled, setIsServiceStepEnabled] = useState(false);
  const [isDateStepEnabled, setIsDateStepEnabled] = useState(false);
  const [isDataStepEnabled, setIsDataStepEnabled] = useState(false);

  useEffect(() => {
    const fetchProfessionals = async () => {
      setIsLoading(true);
      try {
        const q = query(
          collection(db, 'professionals'),
          where('status', '==', 'Activo') // Filtra por estado activo
        );
        const professionalsSnapshot = await getDocs(q);
        const professionalsList = professionalsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProfessionals(professionalsList);

          // Selecciona automáticamente el primer profesional si la lista no está vacía
      if (professionalsList.length > 0) {
        setProfessional(professionalsList[0].id);
        setSelectedCategory(''); // Resetear la categoría seleccionada si es necesario
        setSelectedServiceDetails([]); // Resetear los servicios seleccionados si es necesario

        // También puedes llamar a fetchServicesByProfessional y fetchAvailability aquí si deseas cargar datos relacionados al profesional automáticamente
        await fetchServicesByProfessional(professionalsList[0].id);
        await fetchAvailability(professionalsList[0].id);
      }
      } finally {
        setIsLoading(false);
      }
    };

    const fetchServices = async () => {
      setIsLoading(true);
      try {
        const servicesSnapshot = await getDocs(collection(db, 'services'));
        const servicesList = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setServices(servicesList);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfessionals();
    fetchServices();
  }, []);

  useEffect(() => {
    if (professional) {
      setIsLoading(true);
      Promise.all([
        fetchServicesByProfessional(professional),
        fetchAvailability(professional)
      ]).finally(() => {
        setIsLoading(false);
        setIsServiceStepEnabled(true); // Habilita el paso de servicios
      });
    }
  }, [professional]);

  useEffect(() => {
    if (professional && date) {
      setIsLoading(true);
      fetchApprovedReservations(professional, date).finally(() => {
        setTime('');
        setIsLoading(false);
      });
    }
  }, [date, professional]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(price);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const formatDuration = (duration) => {
    const [hours, minutes] = duration.split(':').map(Number);
    let formattedDuration = '';
    if (hours > 0) {
      formattedDuration += `${hours} hrs `;
    }
    if (minutes > 0) {
      formattedDuration += `${minutes} min`;
    }
    return formattedDuration.trim();
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  const fetchServicesByProfessional = async (professionalId) => {
    try {
      setIsLoading(true);
      const q = query(collection(db, 'serviciosOfrecidos'), where('professionals', 'array-contains', professionalId));
      const servicesSnapshot = await getDocs(q);
      const servicesList = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const sortedServices = servicesList.sort((a, b) => a.order - b.order);
      setServicesOffered(sortedServices);
      setSelectedCategory(sortedServices.length > 0 ? sortedServices[0].category : '');
    } catch (error) {
      console.error("Error fetching services: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailability = async (professionalId) => {
    try {
      setIsLoading(true);
      const q = query(collection(db, 'availability'), where('professionalId', '==', professionalId));
      const availabilitySnapshot = await getDocs(q);
      const availabilityList = availabilitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAvailability(availabilityList);
    } catch (error) {
      console.error("Error fetching availability: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchApprovedReservations = async (professionalId, date) => {
    if (!date) return;
    try {
      setIsLoading(true);
      const q = query(
        collection(db, 'reservations'),
        where('professional', '==', professionalId),
        where('date', '==', date),
        where('status', '==', 'Aprobada')
      );
      const reservationsSnapshot = await getDocs(q);
      const reservationsList = reservationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setApprovedReservations(reservationsList);
    } catch (error) {
      console.error("Error fetching approved reservations: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfessionalSelect = (id) => {
    setProfessional(id);
    setSelectedCategory('');
    setSelectedServiceDetails([]);
    setIsServiceStepEnabled(true); // Habilita el paso de servicios
  };

  const handleDateChange = (selectedDate) => {
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    setDate(formattedDate);
  };

  const handleServiceSelect = (serviceId) => {
    const selectedService = servicesOffered.find(service => service.id === serviceId);
    if (selectedServiceDetails.some(s => s.id === serviceId)) {
      setSelectedServiceDetails(selectedServiceDetails.filter(s => s.id !== serviceId));
    } else {
      setSelectedServiceDetails([...selectedServiceDetails, selectedService]);
    }
    setTime('');
    setIsDateStepEnabled(true); // Habilita el paso de fecha y hora
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleTimeSelect = (selectedTime) => {
    setTime(selectedTime);
    setIsDataStepEnabled(true); // Habilita el paso de datos de contacto
  };

  const openDetailModal = (detail) => {
    setCurrentServiceDetail(detail);
    setIsDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setCurrentServiceDetail('');
    setIsDetailModalOpen(false);
  };

  const validateForm = () => {
    const errors = {};
    const phoneRegex = /^\+569\d{8}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/;
    const selectedDate = parseISO(date);

    if (!date || (!isAfter(selectedDate, new Date()) && !isToday(selectedDate))) {
      errors.date = 'Debe seleccionar una fecha para la atención';
      dateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      dateRef.current.classList.add('highlightReserva');
      setTimeout(() => {
        dateRef.current.classList.remove('highlightReserva');
      }, 4000);
    }

    if (!name || !nameRegex.test(name) || name.length > 150) {
      errors.name = 'Debe ingresar su nombre y apellido (máximo 150 caracteres).';
      nombreRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      nombreRef.current.classList.add('highlightReserva');
      setTimeout(() => {
        nombreRef.current.classList.remove('highlightReserva');
      }, 4000);
    }

    if (!phone || !phoneRegex.test(phone) || phone.length > 15) {
      errors.phone = 'Ingrese un número de celular válido (+569XXXXXXXX, máximo 15 caracteres).';
      numeroRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      numeroRef.current.classList.add('highlightReserva');
      setTimeout(() => {
        numeroRef.current.classList.remove('highlightReserva');
      }, 4000);
    }

    if (email && (!emailRegex.test(email) || email.length > 200)) {
      errors.email = 'Debe ingresar un correo electrónico válido (máximo 200 caracteres).';
      correoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      correoRef.current.classList.add('highlightReserva');
      setTimeout(() => {
        correoRef.current.classList.remove('highlightReserva');
      }, 4000);
    }

    if (!time) {
      errors.time = 'Debe seleccionar una hora para agendar a la profesional por favor';
      toast.warning(errors.time, 'Advertencia');
      horaRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      horaRef.current.classList.add('highlightReserva');
      setTimeout(() => {
        horaRef.current.classList.remove('highlightReserva');
      }, 4000);
    }

    if (selectedServiceDetails.length === 0) {
      errors.service = 'Debe seleccionar un servicio a realizar.';
      toast.warning(errors.service, 'Advertencia');
      servicioRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      servicioRef.current.classList.add('highlightReserva');
      setTimeout(() => {
        servicioRef.current.classList.remove('highlightReserva');
      }, 4000);
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsModalOpen(true);
    }
  };

  const sendWhatsAppMessage = async (phoneNumber, contentSid, contentVariables) => {
    try {
      const response = await axios.post('https://us-central1-marynails-8c245.cloudfunctions.net/sendWhatsAppMessage', {
        phoneNumber,
        contentSid,
        contentVariables
      });
      console.log('Mensaje de WhatsApp enviado:', response.data);
      toast.success('Mensaje WhatsApp enviado con éxito', 'Éxito');
    } catch (error) {
      console.error('Error al enviar el mensaje de WhatsApp:', error);
    }
  };

  const confirmReservation = async () => {
    setIsConfirming(true);
    setIsLoading(true);

    const professionalEmail = professionals.find(p => p.id === professional).email;
    const professionaNumber = professionals.find(p => p.id === professional).contactNumber;

    const reservationData = {
      name,
      date,
      time,
      professional,
      professionalName: professionals.find(p => p.id === professional)?.name,
      professionalEmail,
      phone,
      email,
      services: selectedServiceDetails.map(service => ({
        id: service.id,
        name: service.name,
        price: service.price,
        duration: service.duration
      })),
      status: 'Aprobada',
      totalDuration: getTotalDuration(),
      reservationDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
    };

    try {
      await addDoc(collection(db, 'reservations'), reservationData);

      // Enviar correo de confirmación al profesional
      await sendEmailTemplate(reservationData, 'confirmation');

      // Enviar correo de agradecimiento al cliente
      await sendEmailTemplate({ ...reservationData, professionalEmail: reservationData.email }, 'thankYou');

      // Preparar los parámetros para la plantilla
      const contentSid = "HX4552d477f7624ff80d9f184f5842efe4"; // Reemplaza con el SID de tu plantilla
      const contentVariables = {
        1: reservationData.professionalName,
        2: formatDate(reservationData.date),
        3: reservationData.services.map(service => service.name).join(', '),
        4: reservationData.time,
        5: reservationData.name,
        6: reservationData.email,
        7: reservationData.phone
      };

      // enviar whatsapp a profesional con reserva creada
      await sendWhatsAppMessage(professionaNumber, contentSid, contentVariables);

      // enviar whatsapp a cliente 
      const contentSidCliente = "HX64d1d7a87cfc6f342ad77112fc59959f"; // Reemplaza con el SID de tu plantilla
      const contentVariablesCliente = {
        1: reservationData.name,
        2: reservationData.services.map(service => service.name).join(', '),
        3: formatDate(reservationData.date),
        4: reservationData.time,
        5: reservationData.professionalName,
        6: professionaNumber
      };

      await sendWhatsAppMessage(reservationData.phone, contentSidCliente, contentVariablesCliente);

      toast.success('Reserva realizada con éxito', 'Éxito');

      setIsModalOpen(false);
      setName('');
      setDate('');
      setTime('');
      setProfessional('');
      setPhone('+569');
      setEmail('');
      setSelectedServiceDetails([]);

      await new Promise(resolve => setTimeout(resolve, 3000));

      navigate('/');

    } catch (error) {
      console.error('Error al realizar la reserva:', error);
      toast.error('Hubo un problema al realizar la reserva. Por favor, inténtalo de nuevo.');
    } finally {
      setIsConfirming(false);
      setIsLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const calculateReservedBlocks = (startTime, duration) => {
    if (!startTime || !duration) return [];

    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [durationHour, durationMinute] = duration.split(':').map(Number);
    const endHour = startHour + durationHour;
    const endMinute = startMinute + durationMinute;

    const blocks = [];
    let currentHour = startHour;
    let currentMinute = startMinute;

    while (currentHour < endHour || (currentHour === endHour && currentMinute < endMinute)) {
      blocks.push(`${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`);
      currentMinute += 30;
      if (currentMinute >= 60) {
        currentMinute -= 60;
        currentHour += 1;
      }
    }

    return blocks;
  };

  const convertDurationToMinutes = (duration) => {
    const [hours, minutes] = duration.split(':').map(Number);
    return (hours * 60) + minutes;
  };

  const getAvailableTimes = () => {
    if (!date || !availability.length || selectedServiceDetails.length === 0) return [];
  
    const selectedDate = parseISO(date);
    const selectedDay = capitalizeFirstLetter(format(selectedDate, 'EEEE', { locale: es }));
  
    const validAvailability = availability.find(avail => {
      const startDate = new Date(avail.fecInic);
      const endDate = new Date(avail.fecFin);
      endDate.setDate(endDate.getDate() + 1);
      return new Date(date) >= startDate && new Date(date) <= endDate && avail.availability[selectedDay];
    });
  
    if (!validAvailability) {
      return ["No existen horas disponibles para ese día."];
    }
  
    const totalDuration = selectedServiceDetails.reduce((acc, service) => acc + convertDurationToMinutes(service.duration), 0);
  
    const dayAvailability = validAvailability.availability[selectedDay];
    const start = new Date(`1970-01-01T${dayAvailability.start}:00`);
    const end = new Date(`1970-01-01T${dayAvailability.end}:00`);
    const slots = [];
  
    while (start < end) {
      slots.push(start.toTimeString().substring(0, 5));
      start.setMinutes(start.getMinutes() + 30);
    }
  
    approvedReservations.forEach(reservation => {
      const reservedBlocks = calculateReservedBlocks(reservation.time, reservation.totalDuration || totalDuration);
      reservedBlocks.forEach(block => {
        const index = slots.indexOf(block);
        if (index !== -1) {
          slots.splice(index, 1);
        }
      });
    });
  
    const filteredSlots = slots.filter(slot => {
      const slotTime = new Date(`1970-01-01T${slot}:00`);
      const endTime = new Date(slotTime);
      endTime.setMinutes(endTime.getMinutes() + totalDuration);
  
      let isSlotValid = true;
  
      for (let t = new Date(slotTime); t < endTime; t.setMinutes(t.getMinutes() + 30)) {
        if (!slots.includes(t.toTimeString().substring(0, 5))) {
          isSlotValid = false;
          break;
        }
      }
  
      return isSlotValid;
    });
  
    return filteredSlots;
  };
  
  const getFullAvailability = () => {
    if (!date || !availability.length) return [];

    const selectedDate = parseISO(date);
    const selectedDay = capitalizeFirstLetter(format(selectedDate, 'EEEE', { locale: es }));

    const validAvailability = availability.find(avail => {
      const startDate = new Date(avail.fecInic);
      const endDate = new Date(avail.fecFin);
      endDate.setDate(endDate.getDate() + 1);
      return new Date(date) >= startDate && new Date(date) <= endDate && avail.availability[selectedDay];
    });

    if (!validAvailability) {
      return [];
    }

    const dayAvailability = validAvailability.availability[selectedDay];

    const start = new Date(`1970-01-01T${dayAvailability.start}:00`);
    const end = new Date(`1970-01-01T${dayAvailability.end}:00`);
    const slots = [];

    while (start < end) {
      slots.push(start.toTimeString().substring(0, 5));
      start.setMinutes(start.getMinutes() + 30);
    }

    const fullAvailability = slots.map(slot => {
      const isReserved = approvedReservations.some(reservation =>
          calculateReservedBlocks(reservation.time, reservation.totalDuration).includes(slot)
      );
      return { time: slot, reserved: isReserved };
    });

    return fullAvailability;
  };

  const getTotalDuration = () => {
    const totalMinutes = selectedServiceDetails.reduce((acc, service) => {
      const [hours, minutes] = service.duration.split(':').map(Number);
      return acc + (hours * 60) + minutes;
    }, 0);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const getTotalPrice = () => {
    return selectedServiceDetails.reduce((acc, service) => acc + Number(service.price), 0);
  };

  return (
    <div className={styles.contentReservation}>
      {isLoading && <SpinnerOverlay />}
      <form onSubmit={handleSubmit} className={styles.reservationForm}>
        <label className={styles.lblSubtitulo}>Selecciona tu manicurista</label>
        <div id="dProfesionales" className={styles.dvProfesionales}>
          <div className={styles.professionalList}>
            {professionals.map(prof => (
              <div
                key={prof.id}
                className={`${styles.professionalCard} ${professional === prof.id ? styles.selected : ''}`}
                onClick={() => handleProfessionalSelect(prof.id)}
              >
                <img src={prof.profilePictureUrl} alt={prof.name} className={styles.professionalImg} />
                <label className={styles.profesionalNombre}>{prof.name}</label>
              </div>
            ))}
          </div>
        </div>
        
        {professional && (
          <div id="dServicios" ref={servicioRef}  className={`${styles.reservationFormGroup} ${!isServiceStepEnabled ? styles.disabledStep : ''}`}>
            <div className={styles.overlay} style={{ display: isServiceStepEnabled ? 'none' : 'block' }}></div>
            <label className={styles.lblSubtitulo}>Elige tu servicio</label>
            <div className={styles.categoryButtonContainer}>
              {services.filter(service => 
                servicesOffered.some(so => so.category === service.id)
              ).map(service => (
                <button
                  type="button"
                  key={service.id}
                  className={`${styles.categoryButton} ${selectedCategory === service.id ? styles.selectedCategory : ''}`}
                  onClick={() => handleCategorySelect(service.id)}
                >
                  {service.title}
                </button>
              ))}
            </div>
            <div className={styles.servicesOffered}>
              {servicesOffered
                .filter(so => so.category === selectedCategory)
                .map(so => (
                  <div
                    key={so.id}
                    className={`${styles.serviceCard} ${selectedServiceDetails.some(s => s.id === so.id) ? styles.selectedService : ''}`}
                  >
                    <div className={styles.serviceHeader}>
                      <label className={styles.lblNombreServ}>{so.name}</label>
                      {so.mostChosen && (
                        <span className={styles.mostChosenLabel}>Más Elegido</span>
                      )}
                    </div>
                    <label className={styles.lblDuracionServ}>{formatDuration(so.duration)}</label>
                    <label className={styles.lblNombreServ}>{formatPrice(so.price)}</label>
                    <label className={styles.lblDuracionServ}>{truncateText(so.detail, 65)}</label>
                    {so.detail.length > 65 && (
                      <a
                        type="button"
                        onClick={() => openDetailModal(so.detail)}
                        className={`${styles.masInfo}`}
                      >
                        Más información
                      </a>
                    )}
                    <button
                      type="button"
                      onClick={() => handleServiceSelect(so.id)}
                      className={`${styles.btnAgendar}`}
                    >
                      {selectedServiceDetails.some(s => s.id === so.id) ? 'Eliminar servicio' : 'Agendar servicio'}
                    </button>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div id="dFecha"  ref={dateRef}  className={`${styles.reservationFormGroup} ${!isDateStepEnabled ? styles.disabledStep : ''}`} >
          <div className={styles.overlay} style={{ display: isDateStepEnabled ? 'none' : 'block' }}></div>

          <label className={styles.lblSubtitulo}>Selecciona la fecha de reserva</label>
          <DayCarousel onSelectDate={handleDateChange} />
           {errors.date && <div className={styles.validationError}>{errors.date}</div>}
        
        </div>
        
        <div id="dHoras" ref={horaRef}  className={`${styles.reservationFormGroup} ${!isDateStepEnabled ? styles.disabledStep : ''}`} >
        <div className={styles.overlay} style={{ display: isDateStepEnabled ? 'none' : 'block' }}></div>
          <label className={styles.lblSubtitulo}>Selecciona la hora disponible</label>
          <div className={styles.timeList}>
            {getAvailableTimes().map((timeSlot) => (
              <div
                key={timeSlot}
                className={`${styles.timeButton} ${time === timeSlot ? styles.selectedTime : ''}`}
                onClick={() => handleTimeSelect(timeSlot)}
              >
                {timeSlot}
              </div>
            ))}
            <FcInfo
              className={styles.infoIcon}
              onClick={() => setIsInfoModalOpen(true)}
            />
          </div>
          
          {errors.time && <div className={styles.validationError}>{errors.time}</div>}
        </div>
        
        <div id="dDatos" className={`${styles.reservationFormGroup} ${!isDataStepEnabled ? styles.disabledStep : ''}`}>
    
        <div className={styles.overlay} style={{ display: isDataStepEnabled ? 'none' : 'block' }}></div>
        <label className={styles.lblSubtitulo}>Ingresa tus datos para contactarte</label> 
            <div ref={nombreRef} className={styles.inputWithIcon}>
                <i className="fa fa-user fa-lg fa-fw" aria-hidden="true"></i>
                <input
                type="text"
                placeholder="Nombre Clienta/e"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                maxLength={150} // Fijar la longitud máxima
                />
            </div>
            {errors.name && <div className={styles.validationError}>{errors.name}</div>}

          
            <div  ref={numeroRef} className={styles.inputWithIcon}>
                <i className="fa fa-phone fa-lg fa-fw" aria-hidden="true"></i>
                <input
                type="tel"
                placeholder="Teléfono"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                maxLength={15} // Fijar la longitud máxima
                />
            </div>
            {errors.phone && <div className={styles.validationError}>{errors.phone}</div>}

           
            <div ref={correoRef} className={styles.inputWithIcon}>
                <i className="fa fa-envelope fa-lg fa-fw" aria-hidden="true"></i>
                <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                maxLength={200} // Fijar la longitud máxima
                />
            </div>
            {errors.email && <div className={styles.validationError}>{errors.email}</div>}
        </div>
       
      
      </form>
      
      <ReusableModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        title="Confirmar Reserva"
      >
        <div className={styles.confirmationContent}>
          <p className={styles.confirmationText}>
            <span className={styles.confirmationLabel}>Día:</span> <strong>{formatDate(date)}</strong>
          </p>
          <p className={styles.confirmationText}>
            <span className={styles.confirmationLabel}>Profesional:</span> <strong>{professionals.find(p => p.id === professional)?.name}</strong>
          </p>
          <p className={styles.confirmationText}>
            <span className={styles.confirmationLabel}>Hora:</span> <strong>{time}</strong>
          </p>
          <p className={styles.confirmationText}>
            <span className={styles.confirmationLabel}>Servicios:</span> <strong>{selectedServiceDetails.map(s => s.name).join(', ')}</strong>
          </p>
          <p className={styles.confirmationText}>
            <span className={styles.confirmationLabel}>Duración Total:</span> <strong>{getTotalDuration()}</strong>
          </p>
        </div>

        <div className={styles.confirmationActions}>
          <button onClick={confirmReservation} className={styles.reservationCtaButtonAceptar} disabled={isConfirming}>
            <i className="fas fa-check-circle"></i> Aceptar
          </button>
          <button onClick={() => setIsModalOpen(false)} className={styles.reservationCtaButtonCancelar} disabled={isConfirming}>
            <i className="fas fa-times-circle"></i> Cancelar
          </button>
        </div>
      </ReusableModal>


      <ReusableModal
        isOpen={isInfoModalOpen}
        onRequestClose={() => setIsInfoModalOpen(false)}
        title={`Horas disponibles para el día: ${formatDate(date)}`}
      >
        <div className={styles.reservationAvailabilityList}>
          {getFullAvailability().map(slot => (
            <div 
              key={slot.time} 
              className={`${styles.availabilityCard} ${slot.reserved ? styles.reserved : styles.available}`}
            >
              <div className={styles.timeBlock}>
                <span className={styles.timeText}>{slot.time} </span>
                <span className={styles.statusText}>
                  {slot.reserved ? 'Reservado' : 'Disponible'}
                </span>
                <span className={slot.reserved ? styles.iconReserved : styles.iconAvailable}>
                  {slot.reserved ? '🔒' : '✔️'}
                </span>
              </div>
            </div>
          ))}
        </div>
      </ReusableModal>


      {selectedServiceDetails.length > 0 && (
        <div className={styles.footer}>
             <div className={styles.divFooter1} >
                 <label className={styles.lbltxtfooter}>Servicios seleccionados: {selectedServiceDetails.length}</label>
                 <label className={styles.lblDuracionServ}>{selectedServiceDetails.map(service => service.name).join(', ')}</label>

                 <label className={styles.lbltxtfooter}>Precio total: {formatPrice(getTotalPrice())}</label>
             </div>
             <div>
             <label className={styles.lbltxtfooter}>Profesional: {professionals.find(p => p.id === professional)?.name}</label>
             <button type="button" onClick={handleSubmit}  className={styles.reservationCtaButton}>Reservar</button>
             </div>
        </div>
      )}

      <ReusableModal
        isOpen={isDetailModalOpen}
        onRequestClose={closeDetailModal}
        title="Detalle del Servicio"
      >
        <p>{currentServiceDetail}</p>
      </ReusableModal>
      <ToastContainer />
    
     
    </div>
  );
};

export default Reservation;
