import React, { useState, useEffect } from 'react';
import '../css/fechaReserva.css';

const obtenerDias = (fechaInicio, numDias) => {
  const diasDeLaSemana = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
  const mesesDelAno = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const dias = [];
  const inicio = new Date(fechaInicio);

  for (let i = 0; i < numDias; i++) {
    const dia = new Date(inicio);
    dia.setDate(inicio.getDate() + i);
    dias.push({ nombreDia: diasDeLaSemana[dia.getDay()], fecha: dia.getDate(), mes: mesesDelAno[dia.getMonth()], diaCompleto: new Date(dia.setHours(0, 0, 0, 0)) });
  }
  return dias;
};

function DayCarousel({ onSelectDate }) {
  const [fechaActual, setFechaActual] = useState(new Date());
  const [dias, setDias] = useState([]);
  const [mesSeleccionado, setMesSeleccionado] = useState('');
  const [diaSeleccionado, setDiaSeleccionado] = useState(null);
  const [esFechaAnterior, setEsFechaAnterior] = useState(false);
  const [numDias, setNumDias] = useState(10); // Número de días a mostrar

  useEffect(() => {
    const manejarResize = () => {
      const isMobile = window.innerWidth <= 768;
      setNumDias(isMobile ? 5 : 10); // Muestra 5 días en móvil, 10 días en pantallas más grandes
    };

    manejarResize(); // Configura el número de días basado en la pantalla inicial
    window.addEventListener('resize', manejarResize);

    return () => {
      window.removeEventListener('resize', manejarResize);
    };
  }, []);

  useEffect(() => {
    const diasIniciales = obtenerDias(fechaActual, numDias);
    setDias(diasIniciales);
    setMesSeleccionado(diasIniciales[0].mes);
    verificarFechaAnterior(diasIniciales);
  }, [fechaActual, numDias]);

  const manejarAnterior = () => {
    const nuevaFechaInicio = new Date(fechaActual);
    nuevaFechaInicio.setDate(nuevaFechaInicio.getDate() - numDias);
    setFechaActual(nuevaFechaInicio);
  };

  const manejarSiguiente = () => {
    const nuevaFechaInicio = new Date(fechaActual);
    nuevaFechaInicio.setDate(nuevaFechaInicio.getDate() + numDias);
    setFechaActual(nuevaFechaInicio);
  };

  const manejarSeleccionarDia = (dia) => {
    setDiaSeleccionado(dia.diaCompleto);
    setMesSeleccionado(dia.mes);
    onSelectDate(dia.diaCompleto);
  };

  const manejarSemanaActual = () => {
    setFechaActual(new Date());
  };

  const verificarFechaAnterior = (dias) => {
    const fechaHoy = new Date().setHours(0, 0, 0, 0);
    const esAnterior = dias[0].diaCompleto.getTime() <= fechaHoy;
    setEsFechaAnterior(esAnterior);
  };

  const esHoy = (fecha) => {
    const hoy = new Date().setHours(0, 0, 0, 0);
    return fecha.getTime() === hoy;
  };

  return (
    <div className="contenedor-carrusel">
      <div >
        <label className="mes-seleccionado">{mesSeleccionado}</label> 
      </div>
      <div className="carrusel-semana">
        <button type="button" onClick={manejarAnterior} className="boton-flecha" disabled={esFechaAnterior}>
          &lt;
        </button>
        {dias.map((dia, indice) => (
          <div
            key={indice}
            className={`carrusel-dia ${diaSeleccionado === dia.diaCompleto ? 'seleccionado' : ''} ${dia.nombreDia === 'Dom' ? 'fondo-rojo' : ''}`}
            onClick={() => manejarSeleccionarDia(dia)}
          >
            <div className="lblDia">{dia.nombreDia}</div>
            <div className="lblNumero">{dia.fecha}</div>
            {esHoy(dia.diaCompleto) && <div className="lblHoy">Hoy</div>}
          </div>
        ))}
        <button type="button" onClick={manejarSiguiente} className="boton-flecha">
          &gt;
        </button>
      </div>
      <div className="botones-inferior">
        <button type="button" onClick={manejarSemanaActual} className="boton-semana-actual">Semana Actual</button>
      </div>
    </div>
  );
}

export default DayCarousel;
