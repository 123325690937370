import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAyt74vzE0zaeQU8-ZL9FsixBpcPke1RBI",
    authDomain: "marynails-8c245.firebaseapp.com",
    projectId: "marynails-8c245",
    storageBucket: "marynails-8c245.appspot.com",
    messagingSenderId: "602344610521",
    appId: "1:602344610521:web:e08db5030ed10ba9df7cc8",
    measurementId: "G-DZ8FHPC839"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export { db, auth, storage, signIn };
