import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import '../css/gestionservicios.css'; // Asegúrate de que el archivo CSS esté importado

const GestionServicios = () => {
  // States for GestionServiciosOfrecidos
  const [services, setServices] = useState([]);
  const [professionals, setProfessionals] = useState({});
  const [form, setForm] = useState({
    name: '',
    price: '',
    professionals: [],
    duration: '',
    detail: '',
    category: '',
    mostChosen: false, // Nuevo campo para indicar si es más elegido
    order: 0 // Nuevo campo para el orden
  });
  const [editId, setEditId] = useState(null);

  // States for ServiceForm
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [image, setImage] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [editServiceId, setEditServiceId] = useState(null);
  const [active, setActive] = useState(true); // Nuevo estado para el campo activo/inactivo
  const [order, setOrder] = useState(0); // Nuevo estado para el campo de orden

  useEffect(() => {
    const fetchServicesAndProfessionals = async () => {
      const servicesSnapshot = await getDocs(collection(db, 'serviciosOfrecidos'));
      const servicesList = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).sort((a, b) => a.order - b.order);

      const professionalsSnapshot = await getDocs(collection(db, 'professionals'));
      const professionalsList = professionalsSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data().name;
        return acc;
      }, {});

      setServices(servicesList);
      setProfessionals(professionalsList);

      const serviceSnapshot = await getDocs(collection(db, 'services'));
      const serviceData = serviceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setServiceList(serviceData);
    };

    fetchServicesAndProfessionals();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "professionals") {
      const options = e.target.options;
      const selectedValues = [];
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          selectedValues.push(options[i].value);
        }
      }
      setForm(prev => ({ ...prev, [name]: selectedValues }));
    } else if (name === "price" || name === "order") {
      const numericValue = value.replace(/[^0-9]/g, '');
      setForm(prev => ({ ...prev, [name]: numericValue }));
    } else if (type === "checkbox") {
      setForm(prev => ({ ...prev, [name]: checked }));
    } else {
      setForm(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceData = {
      ...form,
      professionals: form.professionals
    };
    if (editId) {
      await updateDoc(doc(db, 'serviciosOfrecidos', editId), serviceData);
      setEditId(null);
    } else {
      await addDoc(collection(db, 'serviciosOfrecidos'), serviceData);
    }
    setForm({ name: '', price: '', professionals: [], duration: '', detail: '', category: '', mostChosen: false, order: 0 });
    const servicesSnapshot = await getDocs(collection(db, 'serviciosOfrecidos'));
    const servicesList = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).sort((a, b) => a.order - b.order);
    setServices(servicesList);
  };

  const handleEdit = (service) => {
    setForm(service);
    setEditId(service.id);
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'serviciosOfrecidos', id));
    setServices(services.filter(service => service.id !== id));
  };

  const handleServiceSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = '';
      if (image) {
        const imageRef = ref(storage, `services/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const serviceData = {
        title,
        description,
        type,
        image: imageUrl,
        order, // Almacenar el orden
        active // Almacenar el estado activo/inactivo
      };

      if (editServiceId) {
        await updateDoc(doc(db, 'services', editServiceId), serviceData);
        setEditServiceId(null);
      } else {
        await addDoc(collection(db, 'services'), serviceData);
      }

      setTitle('');
      setDescription('');
      setType('');
      setImage(null);
      setOrder(0); // Reiniciar orden
      setActive(true); // Reiniciar activo
      alert('Servicio agregado con éxito');

      const serviceSnapshot = await getDocs(collection(db, 'services'));
      const serviceDataList = serviceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setServiceList(serviceDataList);
    } catch (error) {
      console.error('Error adding service: ', error);
      alert('Error al agregar el servicio');
    }
  };

  const handleServiceEdit = (service) => {
    setTitle(service.title);
    setDescription(service.description);
    setType(service.type);
    setOrder(service.order || 0); // Cargar el orden
    setActive(service.active !== undefined ? service.active : true); // Cargar el estado activo/inactivo
    setImage(null); // No pre-fill image file
    setEditServiceId(service.id);
  };

  const handleServiceDelete = async (serviceId, imageUrl) => {
    try {
      await deleteDoc(doc(db, 'services', serviceId));
      if (imageUrl) {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);
      }

      alert('Servicio eliminado con éxito');

      const serviceSnapshot = await getDocs(collection(db, 'services'));
      const serviceDataList = serviceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setServiceList(serviceDataList);
    } catch (error) {
      console.error('Error deleting service: ', error);
      alert('Error al eliminar el servicio');
    }
  };

  return (
    <div className="srvOfrecidoContent">
      <h2 className="srvOfrecidoTitulo">Gestionar Servicios Ofrecidos</h2>
      <form onSubmit={handleSubmit} className="srvOfrecidoForm">
        <div className="srvOfrecidoFormGroup">
          <label>Nombre:</label>
          <input
            type="text"
            name="name"
            value={form.name}
            onChange={handleChange}
            placeholder="Nombre"
            required
            className="srvOfrecidoInput"
          />
        </div>
        <div className="srvOfrecidoFormGroup">
          <label>Precio:</label>
          <input
            type="text"
            name="price"
            value={form.price}
            onChange={handleChange}
            placeholder="Precio"
            required
            className="srvOfrecidoInput"
          />
        </div>
        <div className="srvOfrecidoFormGroup">
          <label>Profesionales:</label>
          <select
            multiple
            name="professionals"
            value={form.professionals}
            onChange={handleChange}
            required
            className="srvOfrecidoSelect"
          >
            <option value="" disabled>Selecciona uno o más profesionales</option>
            {Object.keys(professionals).map(id => (
              <option key={id} value={id}>{professionals[id]}</option>
            ))}
          </select>
        </div>
        <div className="srvOfrecidoFormGroup">
          <label>Duración en minutos:</label>
          <input
            type="text"
            name="duration"
            value={form.duration}
            onChange={handleChange}
            placeholder="Duración en minutos"
            required
            className="srvOfrecidoInput"
          />
        </div>
        <div className="srvOfrecidoFormGroup">
          <label>Detalle:</label>
          <textarea
            name="detail"
            value={form.detail}
            onChange={handleChange}
            placeholder="Detalle"
            required
            className="srvOfrecidoTextarea"
          />
        </div>
        <div className="srvOfrecidoFormGroup">
          <label>Categoría:</label>
          <select
            name="category"
            value={form.category}
            onChange={handleChange}
            required
            className="srvOfrecidoSelect"
          >
            <option value="">Selecciona una categoría</option>
            {serviceList.map(service => (
              <option key={service.id} value={service.id}>{service.title}</option>
            ))}
          </select>
        </div>
        <div className="srvOfrecidoFormGroup">
          <label>Más Elegido:</label>
          <input
            type="checkbox"
            name="mostChosen"
            checked={form.mostChosen}
            onChange={handleChange}
            className="srvOfrecidoCheckbox"
          />
        </div>
        <div className="srvOfrecidoFormGroup">
          <label>Orden:</label>
          <input
            type="number"
            name="order"
            value={form.order}
            onChange={handleChange}
            placeholder="Orden"
            required
            className="srvOfrecidoInput"
          />
        </div>
        <button type="submit" className="srvOfrecidoButton">{editId ? 'Actualizar' : 'Agregar'}</button>
      </form>
      <table className="srvOfrecidoTable">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Duración</th>
            <th>Detalle</th>
            <th>Profesionales</th>
            <th>Categoría</th>
            <th>Más Elegido</th>
            <th>Orden</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {services.map(service => (
            <tr key={service.id}>
              <td>{service.name}</td>
              <td>{service.price}</td>
              <td>{service.duration} minutos</td>
              <td>{service.detail}</td>
              <td>{(service.professionals || []).map(profId => professionals[profId]).join(", ")}</td>
              <td>{serviceList.find(s => s.id === service.category)?.title}</td>
              <td>{service.mostChosen ? 'Sí' : 'No'}</td>
              <td>{service.order}</td>
              <td>
                <button onClick={() => handleEdit(service)} className="srvOfrecidoEditButton">Editar</button>
                <button onClick={() => handleDelete(service.id)} className="srvOfrecidoDeleteButton">Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="srvOfrecidoContent">
        <h2 className="srvOfrecidoTitulo">Agregar Servicio</h2>
        <form onSubmit={handleServiceSubmit} className="srvOfrecidoForm">
          <div className="srvOfrecidoFormGroup">
            <label>Título</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="srvOfrecidoInput"
            />
          </div>
          <div className="srvOfrecidoFormGroup">
            <label>Descripción</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              className="srvOfrecidoInput"
            />
          </div>
          <div className="srvOfrecidoFormGroup">
            <label>Tipo</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
              className="srvOfrecidoSelect"
            >
              <option value="">Seleccionar tipo</option>
              <option value="manicura">Manicura</option>
              <option value="pedicura">Pedicura</option>
              <option value="pestanas">Pestañas</option>
              <option value="depilacion">Depilación</option>
            </select>
          </div>
          <div className="srvOfrecidoFormGroup">
            <label>Imagen</label>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              className="srvOfrecidoInput"
            />
          </div>
          <div className="srvOfrecidoFormGroup">
            <label>Orden:</label>
            <input
              type="number"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Orden"
              required
              className="srvOfrecidoInput"
            />
          </div>
          <div className="srvOfrecidoFormGroup">
            <label>Activo:</label>
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
              className="srvOfrecidoCheckbox"
            />
          </div>
          <button type="submit" className="srvOfrecidoButton">{editServiceId ? 'Actualizar' : 'Agregar Servicio'}</button>
        </form>
        <h3 className="srvOfrecidoTitulo">Servicios Existentes</h3>
        <ul className="srvOfrecidoServiceList">
          {serviceList.map(service => (
            <li key={service.id} className="srvOfrecidoServiceItem">
              <img src={service.image} alt={service.title} className="srvOfrecidoServiceImage" />
              <span>{service.title} - {service.description}</span>
              <span>Orden: {service.order}</span>
              <span>{service.active ? 'Activo' : 'Inactivo'}</span>
              <button onClick={() => handleServiceEdit(service)} className="srvOfrecidoEditButton">Editar</button>
              <button onClick={() => handleServiceDelete(service.id, service.image)} className="srvOfrecidoDeleteButton">Eliminar</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GestionServicios;
