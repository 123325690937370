import React, { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook para la navegación

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Iniciar sesión con Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Buscar al profesional correspondiente al UID
      const professionalsRef = collection(db, 'professionals');
      const q = query(professionalsRef, where('uid', '==', user.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const professionalData = querySnapshot.docs[0].data();
        setIsLoggedIn(true);
        // Redirigir a la página de "Gestionar Reservas"
        navigate('/manage-reservations'); 
      } else {
        alert('No se encontró información de profesional asociada a este usuario.');
      }
    } catch (error) {
      console.error('Error al iniciar sesión: ', error);
      alert('Error al iniciar sesión. Por favor, verifica tus credenciales e inténtalo nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <form onSubmit={handleLogin}>
        <h3 className="lblTitulo">Iniciar Sesión</h3>
        <div className="form-group">
          <label>Correo Electrónico</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label>Contraseña</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="cta-button" disabled={loading}>
          {loading ? "Cargando..." : "Iniciar Sesión"}
        </button>
      </form>
    </div>
  );
};

export default Login;
