import React, { useState } from 'react';
import '../css/Schedule.css';

function Schedule() {
  const [form, setForm] = useState({
    name: '',
    email: '',
    date: '',
    time: ''
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulario, por ejemplo, enviarlo a un servidor.
    alert('Cita agendada con éxito');
  };

  return (
    <div className="schedule">
      <h2>Agenda tu Cita</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Nombre:
          <input type="text" name="name" value={form.name} onChange={handleChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={form.email} onChange={handleChange} required />
        </label>
        <label>
          Fecha:
          <input type="date" name="date" value={form.date} onChange={handleChange} required />
        </label>
        <label>
          Hora:
          <input type="time" name="time" value={form.time} onChange={handleChange} required />
        </label>
        <button type="submit" className="cta-button">Agendar</button>
      </form>
    </div>
  );
}

export default Schedule;
