// SpinnerOverlay.js
import React from 'react';
import styles from '../css//SpinnerOverlay.module.css';

const SpinnerOverlay = () => {
  return (
    <div className={styles.overlay}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default SpinnerOverlay;
