import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Función para formatear fechas
const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

// Función para enviar correos con diferentes plantillas
const sendEmailTemplate = async (reservationData, templateType) => {
  let subject, text;

  switch(templateType) {
    case 'confirmation':
      subject = `Nueva reserva de ${reservationData.name}`;
      text = `Hola ${reservationData.professionalName},\n\n` +
             `Tienes una nueva reserva de ${reservationData.name} el día ${formatDate(reservationData.date)} a las ${reservationData.time}.\n\n` +
             `Detalles del servicio:\n${reservationData.services.map(service => `${service.name} - ${service.duration} minutos`).join('\n')}\n\n` +
             `Gracias,\nTu equipo`;
      break;

    case 'thankYou':
      subject = `Gracias por agendar con MaryNails`;
      text = `¡Hola! ¿Qué tal, ${reservationData.name}?\n\n` +
             `Gracias por agendar tu servicio ${reservationData.services.map(service => service.name).join(', ')} para el día ${formatDate(reservationData.date)}, a las ${reservationData.time}, con ${reservationData.professionalName}.\n\n` +
             `Recuerda llegar 5 minutos antes de tu hora agendada :), para cualquier duda o consulta, comunícate con tu profesional favorito.\n\n` +
             `Te saluda,\nMaryNails.cl`;
      break;

    default:
      throw new Error('Tipo de plantilla no soportado');
  }

  const templateParams = {
    to: reservationData.professionalEmail,
    subject: subject,
    text: text
  };

  try {
    const response = await axios.post('https://us-central1-marynails-8c245.cloudfunctions.net/sendEmail', templateParams);
    console.log('Correo enviado exitosamente:', response.data);
    toast.success('Correo enviado con éxito', 'Éxito');
  } catch (error) {
    console.error('Error al enviar el correo:', error.message);
    toast.error('Problemas al enviar correo', 'Error');
  }
};

export default sendEmailTemplate;
